import React from 'react';

const SvgSql = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16">
      <path
        fill="#fff"
        d="M.568 1A.57.57 0 0 0 0 1.568v5.127c0 .443.36.805.805.805h4.892c.443 0 .805-.36.805-.805V1.568A.572.572 0 0 0 5.932 1H.568Zm.237.5h4.89c.167 0 .305.138.305.305v1.127C6 2.97 5.97 3 5.932 3H.568A.068.068 0 0 1 .5 2.932V1.805c0-.167.138-.305.305-.305ZM7.75 2v.5h3.654c.328 0 .596.268.596.596v1.103A1.068 1.068 0 0 0 11.404 4H7.75v.5h3.654c.328 0 .596.268.596.596V6.22a1.068 1.068 0 0 0-.596-.2H7.75v.5h3.654c.328 0 .596.266.596.594V8h-1.904C9.492 8 9 8.492 9 9.096v3.31c0 .604.492 1.096 1.096 1.096H12v1.5h-1.25v.5h3v-.5H12.5v-1.5h1.904c.604 0 1.096-.492 1.096-1.096v-3.31C15.5 8.492 15.008 8 14.404 8H12.5V3.096C12.5 2.492 12.008 2 11.404 2H7.75ZM.568 3.5h5.364c.038 0 .066.03.066.068v1.364A.068.068 0 0 1 5.932 5H.568A.068.068 0 0 1 .5 4.932V3.568C.5 3.53.53 3.5.568 3.5zM.5 5.486c.023.003.044.014.068.014h5.364c.024 0 .043-.01.066-.014v1.21A.304.304 0 0 1 5.695 7H.805A.306.306 0 0 1 .5 6.695V5.486zM10.094 8.5h4.31c.328 0 .596.268.596.596v3.308a.597.597 0 0 1-.596.596h-4.31a.597.597 0 0 1-.596-.596V9.096c0-.328.268-.596.596-.596Z"
      />
    </svg>
  );
};

export default SvgSql;
