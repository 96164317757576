import React from 'react';

const SvgGit = () => {
  return (
    <svg enable-background="new 0 0 512 512" id="Layer_1" viewBox="0 0 512 512">
      <g>
        <path d="M258.6,507.2C120.3,507.7,6.5,392.6,9.9,251.7C13.1,117.8,124.1,7.1,262.1,8.7c136.7,1.6,249.5,115.4,246,255.8   C504.8,398,394.7,508,258.6,507.2z M230,387.7c-12.2-3.9-24.6-6.8-36-11.9c-23.2-10.4-37.9-28.4-42.6-53.7   c-3-16.4-1.8-32.9,0.2-49.3c1.8-14.5,6-28,17-38.5c0.8-0.8,0.9-3.1,0.4-4.3c-5.3-14.5-4.7-29-0.4-43.7c1.3-4.4,3.4-4.9,7.2-3.7   c10,3.1,18.1,9.1,25,16.7c3.7,4,7,8.4,10.5,12.6c20.8-13.5,80.3-13.5,102.1-0.2c4.2-4.9,8.2-10,12.7-14.7   c6.7-6.9,14.5-12.2,24-14.8c3.1-0.9,4.8-0.2,5.9,3.1c4.9,14.5,5.8,29,0,43.5c-1.1,2.9-1,4.8,1.3,7c6.9,6.6,11.1,14.9,13.3,24.2   c4.2,17.3,5.1,35,3.9,52.6c-1.3,18.9-8.4,35.5-22.3,48.8c-11.7,11.1-25.7,17.7-41.1,21.6c-5.6,1.4-11.2,2.4-17.2,3.6   c7.7,11.8,9.6,24.6,9.3,38c-0.3,14.3-0.1,28.7-0.1,43c0,1.7,0.1,3.5,0.2,5.3c91.3-16.6,183.5-107.5,173.4-233.4   C467.2,119.4,360.9,27.2,237.9,39C123,50,30.8,152.5,41.2,276.7c9.9,117.5,105.1,187.9,179.6,197.1c0-8.5,0-17,0-25.6   c-4.1,0.6-7.8,1.1-11.4,1.5c-26.8,3-48.1-9-60-32.9c-6.5-13.1-13.4-26.2-26.2-34.6c-1.4-0.9-2.2-2.6-3.4-4.1   c1.1-0.8,1.6-1.2,2.2-1.5c0.8-0.3,1.6-0.4,2.4-0.5c9-0.6,17.5,1,24.2,7.3c4.4,4.1,8.4,9,11.7,14.1c12.6,19.7,34.3,28,56.4,21.4   c3-0.9,4.7-2.2,5-5.7C222.3,403.8,224.9,394.9,230,387.7z" />
      </g>
    </svg>
  );
};

export default SvgGit;
